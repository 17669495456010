@import './../../node_modules/bootstrap/scss/functions';
@import './../../node_modules/bootstrap/scss/variables';
@import './../../node_modules/bootstrap/scss/mixins';
@import './mixins';

/* Colours */
$black: black;
$white: white;
$teal: #20cbd4;
$teal-dark:#59CAD5;
$aubergine: #5a1e58;
$dark-blue: #002f6b;
$pink: #e95160;
$yellow: #ffe500;
$green: #a2c8bf;
$lime-green: #b5cd71;
$blue: #0096e6;
$blue-hover: #1589c9;
$orange: #f68f61;
$gold: #faa61a;
$light-cyan: $teal;
$lighter-grey: #f6f6f6;
$light-grey: #cdcdcd;
$charcoal  : #5A5A5A;
$red       : #EA4F5D;
$grey      : #D2DBE5;
$body-bg: #f6f6f6;
$text-color: #333;
$text-color-light: #5a5a5a;


$button-primary-bg-color: $white;
$button-primary-border-color: $dark-blue;
$button-primary-text-color: $dark-blue;

$button-primary-bg-hover-color: $dark-blue;
$button-primary-hover-text-color: $white;

// $questions-left-border-color: #02006c;

// $btn-text-color: #949394;
$card-box-shadow-color: rgba(0, 0, 0, 0.2);

$tablet-min-width: 768px;
$mobile-max-width: 767px;

$header-height       : 107px;
$header-height-mobile: 68px;

/**
 * Colors
 */

 .bg-red{
    background-color: $red;
  }
  
  .bg-white{
    background-color: $white;
  }
  
  .bg-blue{
    background-color: $dark-blue;
  }
  