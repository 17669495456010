@import './../../styles/variables';

.results-container {
  .btn-group-wrapper{
    justify-content: flex-start !important;
    display: flex !important;
    margin-top: 60px;

    @include media-breakpoint-down(sm) {
      margin-top: 0px;
      margin-bottom: 50px;
    }
  }

  a.btn-group{
    text-decoration: none;

    .btn{
      border-right: none;
      border-radius: 3px 0 0 3px;
      margin-right: -1px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      color: #212529;
      letter-spacing: -0.26px;
      text-align: center;
      border: none;
      padding: 0 15px;
      border: 2px solid #212529;
      cursor: pointer;
      opacity: .9;
      outline: none;

      @include media-breakpoint-down(sm) {
        padding: 0 9px;
        font-size: 14px;
      }
    }

    .img-certnz{
      border-radius: 0 3px 3px 0;
      max-width: initial;
      opacity: .95;

      @media (max-width: 575px) {
        width: 125px;
      }
    }

    &:hover{
      opacity: 1;
      box-shadow: 0 1px 3px 0 rgba(0,47,107,.3);
      text-decoration: none;

      .btn{
        opacity: 1;
        box-shadow: none;
        text-decoration: none;
      }

      .img-certnz{
        opacity: 1;
      }
    }
  }
}

.container-fluid section.results-container .content-wrapper a{
  @include media-breakpoint-down(sm) {
    text-decoration: none;
  }
}
