@import './../../styles/variables';

footer.footer {
  background-color: $white;
  padding: 60px 0;
  color: $dark-blue;

  .brought-by {
    color: $dark-blue;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.015em;
    padding-bottom: 15px;
  }

  .footer-menu {
    .link {
      color: $dark-blue !important;
      font-size: 20px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}