@include media-breakpoint-down(sm) {
  .container-fluid {
    > .row > .col-sm-12 {
      height: 100%;
    }

    .read-through {
      margin-bottom: auto;
    }

    .back-button-wrapper-mobile {
      margin-top: auto;
    }

    section {
      font-size: 18px;
    }
  }
}
