@import './../../styles/variables';

header.header-mobile-nav{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 100%;
  left: 0;
  background-color: #fff;
  z-index: 99;
  color: $dark-blue;
  overflow-y: auto;
  display: none;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
  -webkit-transition: bottom .5s ease;
     -moz-transition: bottom .5s ease;
      -ms-transition: bottom .5s ease;
       -o-transition: bottom .5s ease;
          transition: bottom .5s ease;

  @media (max-width: 1140px) {
    display: block;
  }

  .header-mobile-nav-content{
    position: relative;
    padding: 100px 25px 25px 25px !important;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 575px) {
      padding: $header-height-mobile 25px 25px 25px;
      justify-content: flex-end;
    }
  }

  .header-mobile-nav-footer{
    padding: 25px 0;
    margin: 25px 15px 10% 15px;
    border-top: 1px solid $dark-blue;
  }

  .header-menu{
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 15px;

    li{
      display: inline-block;
      margin: 25px 0;
      width: 100%;
      text-align: right;

      a{
        position: relative;
        display: block;
        color: $dark-blue;
        font-size: 28px;
        line-height: 1.2;
        padding-right: 12px;
        text-decoration: none;
        -webkit-transition: padding-right .25s ease;
           -moz-transition: padding-right .25s ease;
            -ms-transition: padding-right .25s ease;
             -o-transition: padding-right .25s ease;
                transition: padding-right .25s ease;

        &:after{
          content: " ";
          position: absolute;
          display: block;
          top: 0px;
          right: 0;
          border-right: 2px solid $dark-blue;
          height: 0;
          -webkit-transition: height .25s ease;
             -moz-transition: height .25s ease;
              -ms-transition: height .25s ease;
               -o-transition: height .25s ease;
                  transition: height .25s ease;
        }

        &.active,
        &:hover{
          padding-right: 16px;

          &:after{
            height: 100%;
          }
        }

        &.active{
          font-size: 28px;
        }
      }
    }
  }
}

header.header{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $header-height;
  background-color: white;
  z-index: 99;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
  -webkit-transition: height .25s ease;
   -moz-transition: height .25s ease;
    -ms-transition: height .25s ease;
     -o-transition: height .25s ease;
        transition: height .25s ease;

  .header-nav-mobile{
    display: none;
  }

  @media (max-width: 1140px) {
    height: $header-height-mobile;

    .container {
      max-width: 100% !important;
    }
    
    .get-scam-savvy{
      max-width: 122px;
    }

    .header-logo{
      max-width: 74px;
    }

    .header-nav{
      display: none !important;
    }
    .header-nav-mobile{
      display: flex;
    }
  }

  .header-menu{
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;

    li{
      display: inline-block;
      margin: 0 15px;

      &:first-child{
        margin-left: 0;
      }

      &:last-child{
        margin-right: 0;
      }

      a{
        position: relative;
        display: inline-block;
        color: $dark-blue;
        font-size: 18px;
        padding-bottom: 0;
        text-decoration: none;
        -webkit-transition: padding-bottom .25s ease;
           -moz-transition: padding-bottom .25s ease;
            -ms-transition: padding-bottom .25s ease;
             -o-transition: padding-bottom .25s ease;
                transition: padding-bottom .25s ease;

        &:after{
          content: " ";
          position: relative;
          display: block;
          margin-top: 2px;
          border-top: 4px solid $dark-blue;
          width: 0;
          -webkit-transition: width .25s ease;
             -moz-transition: width .25s ease;
              -ms-transition: width .25s ease;
               -o-transition: width .25s ease;
                  transition: width .25s ease;
        }

        &.active,
        &:hover{
          padding-bottom: 8px;

          &:after{
            width: 100%;
          }
        }

        &.active{
          font-size: 20px;
          padding-bottom: 10px;
        }
      }
    }
  }

  .container,
  .row,
  .col {
    height: 100%;
  }
}

.header-logo-wrapper{
  display: flex;
  height: 100%;
  padding: 0 26px;
  background-color: $dark-blue;
  .header-nav-mobile & {
    padding: 0 15px;
  }
  .header-logo{
    width: 135px;
    height: auto;
    margin: auto;
  }
}
.header-nav-mobile{
  .hamburger-box, .get-scam-savvy{
    margin-top: 5px;
  }
}

.lang-select-wrapper{
  position: relative;
  margin-left: 9px;
  display: inline-block;

  &:after{
    content: " ";
    position: absolute;
    bottom: -4px;
    left: 50%;
    -webkit-transform: translateX(-50%);
       -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
         -o-transform: translateX(-50%);
            transform: translateX(-50%);
    height: 4px;
    width: 100%;
    background-color: $dark-blue;
  }
  
  .lang-select{
    border: none;
    font-size: 14px;
    padding: 4px 20px 4px 1px;
    font-family: 'Serrano Pro', sans-serif;
    position: relative;
    outline: none;
    height: 30px;
    -webkit-appearance: none;
    background-image: url('../../assets/images/icon-arrow-down.svg');
    background-position: center right;
    background-size: 12px 6px;
    background-repeat:no-repeat;
    background-color: #fff;
  }
}
