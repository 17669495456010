/** include bootstrap grid and required components **/
@import './../../node_modules/bootstrap/scss/functions';
@import './../../node_modules/bootstrap/scss/variables';
@import './../../node_modules/bootstrap/scss/mixins';
@import './../../node_modules/bootstrap/scss/bootstrap-reboot';
@import './../../node_modules/bootstrap/scss/bootstrap-grid';
@import './../../node_modules/bootstrap/scss/utilities';
@import './../../node_modules/bootstrap/scss/buttons';
@import './../../node_modules/bootstrap/scss/forms';
@import '../assets/fonts/css/fonts.css';
@import '../assets/fonts/css/fontawesome-all.min.css';
@import './variables.scss';
@import './mixins';
@import './utilities';
@import './primary';
@import './forms';
@import './buttons';
@import './animate';
@import './responsive';
@import './hamburgers/hamburgers';
@import './../components/index';
@import '../styles/main.scss';
@import '../components/questions/shared/styles/bnz-scam-savvy-answer';
@import '../components/questions/shared/styles/bnz-scam-savvy-question';
@import '../components/questions/shared/styles/bnz-scam-savvy-scenario';

body {
    min-height: 100%;
    margin: 0;
    margin-top: $header-height;
    box-sizing: border-box;
    font-size: 16px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    line-height: 1.2;
    font-family: 'Serrano Pro', sans-serif;
    text-rendering: optimizeLegibility;
  
    @media (max-width: 1140px) {
      margin-top: $header-height-mobile;
    }
  
    @media (max-width: 575px) {
      line-height: 1.1rem;
      font-size: 13px;
    }
  }
  
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  ul,
  li,
  a {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
  }
  
  /* responsive images */
  img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
  }
  
  input, select { font-size: inherit; }
  
  p {
    color: $dark-blue;
    font-size: 16px;
    line-height: 26px;
  }
