@import '../../styles/variables';

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(43, 43, 43, 0.5);
  z-index: 98;
  overflow-y: auto;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  .modal-container {
    margin-top: calc(#{$header-height} + 3%);
    margin-bottom: 3%;

    @include media-breakpoint-down(md) {
      margin-top: calc(#{$header-height-mobile} + 3%);
      max-width: 100%;
    }

    &.download-container {
      .form-group {
        label {
          @include font-sizing(16, 20);
        }

        .form-control {
          color: $black;
          border: 0;
          border-bottom: 1px solid $black;
          border-radius: 0;
          padding-left: 0;
          padding-right: 0;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }

    .modal-content {
      position: relative;
      background-color: $white;
      padding: 3% 8%;
      max-width: 1110px;
      margin:0 auto;

      @include media-breakpoint-down(sm) {
        padding: 30px;
      }

      .close {
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 10px;
        cursor: pointer;
        @include font-sizing(20, 30);
      }

      h1 {
        @include font-sizing(36, 62);
        line-height: 1.2;
        color: $dark-blue;
      }

      h2 {
        @include font-sizing(28, 42);
        line-height: 1.2;
        color: $dark-blue;
      }

      p {
        color: $black;
      }
    }
  }
}
