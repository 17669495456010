@import './../../styles/variables';
@import './shared/styles/bnz-scam-savvy-question';
@import './shared/styles/bnz-scam-savvy-answer';
@import './shared/styles/bnz-scam-savvy-scenario';


.header-question-mobile {
  display: none;

  @include media-breakpoint-down(sm) {
    display: flex;
    padding-top: 18px;
    margin-bottom: 18px;

    &.mobile-question{
      display: none;
    }

    .step-number {
      color: #1b1464;
      font-size: 14px;
      text-align: center;
    }

    a.btn {
      font-size: 24px;
      color: $dark-blue;
      padding: 0px;
      line-height: 24px;
    }
  }
}

.read-through .react-reveal,
.badge img.react-reveal,
.question-card.react-reveal{
  @include media-breakpoint-down(sm) {
    opacity: 1 !important;
    animation: none !important;
    transform: none !important;
  }
}

label.react-reveal,
.question-title.react-reveal,
.left-panel-container > div.react-reveal {
  @include media-breakpoint-down(sm) {
    -webkit-animation-name: fadeIn !important;
    animation-name: fadeIn !important;
  }
}

.question-four-container .question-title.react-reveal,
.question-four-container label.react-reveal {
  @include media-breakpoint-down(sm) {
    opacity: 1 !important;
    animation: none !important;
    transform: none !important;
  }
}

.qa-base-container {
  min-height: calc(100vh - #{$header-height}) !important;
  height: auto !important;

  @media only screen and (max-width: 1024px) {
    min-height: calc(100vh - #{$header-height-mobile}) !important;    
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  span.link {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }

  .divider.top {
    display: inline-block;
    float: left;
    margin-left: -85px;
    margin-top: 12px;
    height: 4px;
    background-color: $dark-blue;
    width: 71px;
  }

  .hidden-on-mobile {
    @include media-breakpoint-down(sm) {
      display: none !important;
    }
  }

  .hidden-on-desktop {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  .btn-primary {
    @include media-breakpoint-down(sm) {
      min-width: 142px;
    }

    @media only screen and (max-width: 480px) {
      min-width: 122px;
    }
  }
  .back-button-wrapper {
    padding: 30px 0 30px 10px;
  }

  .btn-back-button {
    display: inline-flex;
    align-items: center;
    color: $dark-blue !important;
    cursor: pointer;
    @include font-sizing(18, 20);

    i {
      @include font-sizing(18, 20);
      padding-right: 10px;
    }
  }

  .restart-button {
    padding: 30px 10px 30px 15px;
    clear: both;
    margin-bottom: 30px;

    .step-number {
      color: $dark-blue;
      font-size: 20px;
      width: 50%;
      float: left;
    }

    a {
      font-size: 24px;
      float: right;
      color: $dark-blue;
      padding: 0px;
      cursor: pointer;

      @include media-breakpoint-up(sm) {
        &:hover {
          color: $blue-hover;
        }
      }
    }
  }

  .next-button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 25px;
  }

  .btn-read-more-wrapper {
    display: none;

    .btn-next-button {
      display: none;
    }
  }

  .fake-back-btn-wrapper {
    display: none;
  }

  .btn-next-question-button {
    margin-left: auto;
    display: block;
  }

  /* When we go forward from scenario to readme part */
  &.mobile-fake-forward-active {
    .read-through {
      @include media-breakpoint-down(sm) {
        opacity: 1;
        height: auto;
        min-height: 1px;
        overflow-y: auto;
        padding: 15px;
      }
    }
  }

  .read-through {
    @include media-breakpoint-down(sm) {
      top: 250%;
      position: relative;
      width: 100%;
      padding: 0;
      height: 0;
      opacity: 0;
      overflow: hidden;
      min-height: 0;
      transition: top 0.5s ease-in-out, height 0.5s ease-out,
      opacity 0.1s ease-in, overflow 0.5s ease-in;

      .app-container.rightc {
        padding: 0 0 38px;
        overflow-x: hidden;
      }
    }
  }

  /* When we are transitioning from scenario to question or from question to answer */
  @include media-breakpoint-down(sm) {
    &.question-transition {
      .rightc {
        -webkit-backface-visibility: visible !important;
        backface-visibility: visible !important;
        -webkit-animation-name: flipInY;
        animation-name: flipInY;
        height: auto;
      }

      .left-panel-container {
        opacity: 0;
      }
    }

    &.question-transition-copy {
      .rightc > .row {
        opacity: 0;
      }
    }

    &.answer-transition {
      .rightc {
        right: 110% !important;
        opacity: 0;
      }

      .leftc {
        right: -110% !important;
        opacity: 1;
      }

      .left-panel-container {
        opacity: 0;
      }
    }
  }
}

.app-container {
  .left-panel-container {
    .left-panel-content {
      padding: 10px 20px;
      @include media-breakpoint-down(sm) {
        padding: 20px 0px;
      }
    }

    .divider {
      float: right;
      margin-top: 13px;
      height: 3px;
      width: 50px;

      @include media-breakpoint-down(sm) {
        height: 4px;
        width: 58px;
      }
    }

    .image-content {
      margin-left: 15%;
      margin-top: 0px;
      position: relative;

      @include media-breakpoint-up(md) {
        min-height: 360px;
      }
    }
  }
}

.right-panel-container {
  .right-panel-content {
    margin-top: 0px;

    .divider-wrapper {
      display: none;
    }
  }
}

.flex {
  display: flex;

  > * {
    flex: 1;
  }
}

.container-fluid section.results-container .content-wrapper.tryitonline a.btn {
  text-decoration: none;
  position: relative;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: #1b1464;
  }
}
