@import '../../styles/variables.scss' ;


.header-options{
  color: $white;
  padding-top: 145px;
  padding-bottom: 145px;

  @media (max-width: 1024px) {
    padding-top: 105px;
    padding-bottom: 105px;
  }

  .img-scam-savvy{
    display: block;
    margin: 0 auto;

    @media screen and (max-width: 762px) {
      max-width: 248px;
    }
  }

  .headline{
    @include font-sizing(36,62);
    line-height: 1.1;
    text-align: center;
    max-width: 740px;
    margin: 0 auto;
    font-weight: 300;

    @media (max-width: 1024px) {
      padding: 0 35px;
    }
  }

  .sub-heading-sub-header{
    @include font-sizing(18,28);
    line-height: 1.35;
    text-align: center;
    color: $white;
    max-width: 915px;
    margin: 0 auto;

    @media (max-width: 1024px) {
      padding: 0 35px;
    }
  }

  .down-arrow {
    margin-top: 85px;

    @media screen and (max-width: 991px) {
      margin-top: 50px;
    }
  }
}