@import './../../../../styles/variables';

body .container-fluid .bg-light-cyan.answer-left-part {
  @include media-breakpoint-down(sm) {
    background: transparent;
  }
}

.app-container.answer-container {
  .right-panel-container {
    .right-panel-content {
      .answer-card {
        background: $white;
        padding: 40px 15px;

        @include media-breakpoint-up(md) {
          padding: 40px;
          -webkit-box-shadow: 0px 4px 4px $card-box-shadow-color;
          box-shadow: 0px 4px 4px $card-box-shadow-color;
          margin-left: -10%;
          margin-right: 10px;
        }

        @media only screen and (max-width: 340px) {
          padding-top: 20px;
        }

        ul {
          padding-left: 25px;
        }

        .divider {
          background-color: $text-color-light;
          width: 77px;
          height: 5px;
          margin-bottom: 20px;
          margin-top: 15px;
          
          @include media-breakpoint-down(sm) {
            margin-top: 15px;
          }
        }

        .answer-title {
          font-size: 32px;
          line-height: 1.2;
          color: $text-color-light;
          font-weight: 300;

          @include media-breakpoint-down(sm) {
            font-size: 22px;
            line-height: 32px;
            margin-right: 110px;
          }
        }

        .answer-text {
          font-size: 18px;
          line-height: 25px;
          margin-bottom: 20px;
          clear: both;
          color: $dark-blue;

          @include media-breakpoint-down(sm) {
            font-size: 16px;
            line-height: 23px;
          }
        }
      }
    }
  }
}
