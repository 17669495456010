@import './../../../../styles/variables';

$email-title-color: #ddd;

.app-container {
  &.scenario-container {
    .left-panel-container {
      .left-panel-content {
        @include media-breakpoint-down(sm) {
          padding: 20px 5px;
        }
      }
    }
  }
}

.scenario-container {
  .left-panel-container {
    .left-panel-content {
      width: 80%;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      h1 {
        font-size: 64px;
        line-height: 64px;
        color: $dark-blue;
        margin-bottom: 28px;

        @include media-breakpoint-down(sm) {
          font-size: 32px;
          line-height: 42px;
        }
      }

      h4 {
        font-size: 32px;
        line-height: 36px;
        color: $dark-blue;

        @include media-breakpoint-down(sm) {
          font-size: 22px;
          line-height: 25px;
        }
      }

      .divider {
        background-color: $dark-blue;
        float: none;
        margin: 30px 0;
        width: 71px;
        height: 5px;
      }

      p {
        font-size: 18px;
        line-height: 30px;
        color: $text-color-light;

        @include media-breakpoint-down(sm) {
          font-size: 16px;
          line-height: 23px;

          br {
            display: none;
          }
        }
      }
    }
  }

  .right-panel-container {
    .right-panel-content {
      .email-content-wrapper {
        overflow: hidden;

        @include media-breakpoint-up(md) {
          margin-left: -20%;
          -webkit-box-shadow: 5px 5px 10px 0 $card-box-shadow-color;
          box-shadow: 5px 5px 10px 0 $card-box-shadow-color;
          border-radius: 10px;
        }

        .email-header {
          width: 100%;
          background: $email-title-color;
          height: 30px;
          margin: 0;
          padding: 5px 10px;
          border-radius: 10px 10px 0px 0px;

          @include media-breakpoint-down(sm) {
            display: none;
          }

          // span {
          //   width: 10px;
          //   height: 10px;
          //   border-radius: 50%;
          //   display: inline-block;
          //   margin-right: 5px;

          //   &.close {
          //     background-color: $email-message-close-color;
          //   }

          //   &.maximise {
          //     background-color: $email-message-maximise-color;
          //   }

          //   &.minimise {
          //     background-color: $email-message-minimise-color;
          //   }
          // }
        }

        hr{
          margin: 0;
          border-top: none;
          border-bottom: 1px solid #efefef;
        }

        .email-content {
          background-color: $white;
          border-radius: 10px;
          overflow: hidden;
          border-radius: 0 0 10px 10px;
          
          @include media-breakpoint-down(sm) {
            border-radius: 10px;
          }

          div {
            p {
              font-size: 16px;
              line-height: 22px;

              @include media-breakpoint-down(sm) {
                line-height: 25px;
                margin-bottom: 25px;
              }
            }
          }
        }
      }

      .great-prizes-guaranteed {
        @include media-breakpoint-down(sm) {
          padding: 30px 40px;
        }
      }
    }

    button.btn {
      display: none;

      @include media-breakpoint-down(sm) {
        float: right;
        margin-right: 30px;
        display: inline-block;
      }
    }

    a.btn {
      display: none;
      @include media-breakpoint-down(sm) {
        margin-left: 17px;
        display: inline-block;
      }
    }

    .contentmsg-wrapper {
      @include media-breakpoint-up(md) {
        margin-left: -20%;
        -webkit-box-shadow: 5px 5px 10px 0 $card-box-shadow-color;
        box-shadow: 5px 5px 10px 0 $card-box-shadow-color;
        border-radius: 10px;
        overflow: hidden;
      }

      .contentmsg-content {
        background: $white;
        padding: 40px 30px 40px 30px;

        @include media-breakpoint-down(sm) {
          padding: 30px 25px 30px 25px;
        }

        div {
          .quote {
            position: relative;
            top: -6px;
            color: $gold;
          }

          p {
            font-size: 18px;
            color: $text-color;
            line-height: 30px;

            @include media-breakpoint-down(sm) {
              line-height: 25px;
            }
          }

          p:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    .textmsg-content-wrapper {
      @include media-breakpoint-up(md) {
        margin-left: -20%;
        -webkit-box-shadow: 5px 5px 10px 0 $card-box-shadow-color;
        box-shadow: 5px 5px 10px 0 $card-box-shadow-color;
      }

      .textmsg-content {
        background: $white;
        padding: 40px 30px 35px 30px;

        @include media-breakpoint-down(sm) {
          padding: 60px 25px 60px 25px;
        }

        div {
          p {
            font-size: 18px;
            color: $text-color;
            line-height: 30px;

            @include media-breakpoint-down(sm) {
              line-height: 23px;
            }
          }
        }
      }
    }

    .smartphone-content-wrapper {
      .smartphone-content {
        @include media-breakpoint-down(sm) {
          text-align: center;
          padding: 30px;
        }

        img {
          -webkit-box-shadow: 5px 5px 10px 0 $card-box-shadow-color;
          box-shadow: 5px 5px 10px 0 $card-box-shadow-color;
          border-radius: 25px;
        }
      }
    }
  }

  &.scenario-p4-container {
    margin-top: 0 !important;

    .right-panel-container {
      .right-panel-content {
        .email-content-wrapper {
          .email-content {
            padding: 0;

            img {
              width: 100%;

              @include media-breakpoint-down(sm) {
                width: 97%;
                margin: 0 auto 25px;
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

.qa-base-container {
  .scenario-b4-container {
    img {
      display: inline !important;

    }
    .row.h-100 {
      height: auto !important;
      min-height: 100%;
      padding-bottom: 20%;
    }
    
    
  }.scenario-b5-container {
    img {
      display: inline !important;
    }
  }
  .scenario-container {
    .btn-read-more-wrapper {
      @include media-breakpoint-down(sm) {
        display: block;
        margin-bottom: 30px;
        padding: 0 3px;
      }

      .btn-primary {
        min-width: 140px;
        padding: 12px;

        @media only screen and (max-width: 375px) {
          min-width: 116px;
        }
      }

      .btn-next-button {
        float: right;
      }
    }
  }
  &.mobile-fake-forward-active {
    .fake-back-btn-wrapper {
      display: none;

      @include media-breakpoint-down(sm) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 30px;
        margin-top: 30px;
      }
    }

 
    .scenario-p4-container {
      .row.h-100 {
        height: auto !important;
        min-height: 100%;
        padding-bottom: 20%;
      }
    }
    
    @include media-breakpoint-down(sm) {
      .read-through .app-container.rightc {
        &.scenario-container {
          background-color: $lighter-grey;

          .contentmsg-content {
            font-size: 18px;
            line-height: 25px;
            border-radius: 10px;
            overflow: hidden;
          }
        }

        &.scenario-p4-container {
          .question-title {
            padding: 50px 0 0 15px;
            display: block;
          }

          .email-content {
            background-color: transparent;
          }
        }

        &.scenario-five-container {
          background-color: $lighter-grey;
        }

        &.scenario-five-container .textmsg-content > div {
          background-color: rgb(233, 232, 241);
          padding: 15px 20px;
          border-radius: 10px;
          position: relative;

          &::after {
            top: 100%;
            left: 92%;
            border: solid transparent;
            content: ' ';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: #ffffff;
            border-top-color: #e9e8f1;
            border-width: 9px;
            margin-left: -9px;
          }
        }
      }
    }
  }

  &.mobile-fake-forward-been-there {
    .btn-read-more-wrapper {
      .btn-read-more {
        opacity: 0.5;
      }
      
      .btn-next-button {
        display: inline-block;
      }
    }
  }
}
