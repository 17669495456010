@import 'variables';

.form {
  .checkbox {
    .checkmark {
      position: absolute;
      height: 30px;
      width: 30px;
      background-color: transparent;
      border: 3px solid $dark-blue;

      &:after {
        content: '';
        position: absolute;
        display: none;
      }
    }

    label {
      width: 100%;
      display: block;
      position: relative;
      margin-bottom: 20px;
      cursor: pointer;
      user-select: none;
      padding-bottom: 20px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      i {
        font-size: 18px;
        color: $dark-blue;
        z-index: 999;
        position: absolute;
        padding: 3px 0 0 3px;
        display: none;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked ~ .checkmark {
          i {
            display: block;
          }
        }

        &:checked ~ .checkmark:after {
          display: block;
        }
      }

      span:last-child {
        margin-left: 50px;
        color: $dark-blue;
      }
    }
  }
}
