@import 'variables';

/*
 * Override max width
 */
.container {
  max-width: 1440px;
}

h1, h2, h3, h4, h5, h6{
  font-weight: 300;
}

.sub-heading{
  font-weight: 300;
}

body {
  background: $body-bg;
  font-size: 16px;
  font-family: 'Serrano Pro', sans-serif;
  color: $text-color;
  margin-top: $header-height;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  @media (max-width: 1140px) {
    margin-top: $header-height-mobile;
  }

  .vh-100 {
    height: 100vh;
  }

  .divider {
    width: 76px;
    height: 6px;
    background-color: $text-color;
    &.bg-dark-blue {
      background-color: $dark-blue;
    }

    @include media-breakpoint-down(sm) {
      width: 133px;
    }
  }

  .no-hor-scroll-wrapper {
    overflow-x: hidden;
  }

  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  .container-fluid {
    transform: none;

    &.w-1200 {
      max-width: 1200px;
    }

    &.w-100 {
      max-width: 100%;
    }

    .center-it {
      margin-left: 45%;
    }

    .bg-light-cyan {
      @include media-breakpoint-down(sm) {
        &.read-through {
          flex-grow: 1;
          background-color: $light-cyan;
        }
      }

      @include media-breakpoint-up(md) {
        background-color: $light-cyan;
      }
    }

    .app-container {
      margin: 0 auto;

      &.leftc,
      &.rightc {
        width: 600px;

        @include media-breakpoint-down(sm) {
          width: 100%;
          background-color: $white;
          box-shadow: 7px 9px 13px 3px $card-box-shadow-color;
          border-radius: 10px;
          margin-top: 0 !important;
          margin-bottom: 40px;
        }
      }

      &.leftc {
        &.question-container,
        &.answer-container {
          @include media-breakpoint-down(sm) {
            box-shadow: none;
            position: absolute;
            z-index: 19;
            width: 160px;
            background-color: transparent;
            top: 0;
            margin-top: 50px !important;
            right: 20px;
          }

          @media only screen and (max-width: 340px) {
            width: 138px;
          }
        }

        /* When we are transitioning from answer to scenario */
        &.scenario-container {
          @include media-breakpoint-down(sm) {
            transition: right 0.2s ease-in, opacity 0.2s ease-in;
            right: 0;

            &.mobile-question {
              display: none;
            }
          }
        }
      }

      &.rightc {
        margin: 0 auto;
        
        @include media-breakpoint-down(sm) {
          animation-duration: 1.6s;
          animation-fill-mode: both;
          transform-style: preserve-3d;
          transition: height 0.9s ease-in;
        }

        /* When we are transitioning from answer to scenario */
        &.answer-container {
          @include media-breakpoint-down(sm) {
            transition: right 0.5s ease-out, opacity 0.5s ease-out;
            right: 0;
          }

          + .next-button .btn-next-button{
            display: none !important;
          }
        }
      }

      .left-panel-container {
        @include media-breakpoint-down(sm) {
          transition: opacity 0.2s ease-out;
        }
      }

      .btn-back {
        background: inherit;
        color: $text-color;
        font-size: 16px;
      }
    }
  }

  label,
  p {
    -webkit-text-stroke: unset;
  }

  p {
    color: $dark-blue;
    font-size: 16px;
    line-height: 26px;
  }

  .btn-primary {
    background-color: transparent;
    border: 2px solid $button-primary-border-color;
    border-radius: 0;
    color: $button-primary-text-color;
    padding: 15px 29px;
    line-height: 1.1;
    
    @include font-sizing(18, 20);

    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus {
      background-color: $button-primary-bg-hover-color;
      border-color: $button-primary-border-color;
      color: $button-primary-hover-text-color;
      transform: scale(1);
      transition: 0.25s;
      box-shadow: 0 0 0 0;
    }

    &:disabled,
    &.disabled {
      background-color: $button-primary-bg-color;
      border-color: $button-primary-border-color;
      color: $button-primary-text-color;
      opacity: 0.5;
    }

    &.btn-white{
      border: 2px solid $white;
      color: $white;

      &:hover,
      &:active,
      &:focus,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):active:focus {
        background-color: $white;
        border-color: $white;
        color: $dark-blue;
      }
    }
  }

  .btn-image {
    img {
      margin: 0;
      vertical-align: inherit;
    }
  }

  .long-link {
    word-break: break-all;
  }
}
