/**
 * Text color
 */
.color {
  &-white {
    color: $white !important;
  }

  &-orange {
    color: $orange !important;
  }

  &-lime-green {
    color: $lime-green !important;
  }

  &-dark-blue {
    color: $dark-blue !important;
  }

  &-purple {
    color: $aubergine !important;
  }

  &-grey {
    color: $text-color !important;
  }

  &-light-grey {
    color: $text-color-light !important;
  }

  &-gold {
    color: $gold !important;
  }

  &-red {
    color: $red !important;
  }
}

/**
 * Background colors
 */
.bg {
  &-gold {
    background-color: $gold !important;
  }

  &-purple {
    background-color: $aubergine !important;
  }

  &-white {
    background-color: $white !important;
  }

  &-black {
    background-color: $black !important;
  }

  &-pink {
    background-color: $pink !important;
  }

  &-teal {
    background-color: $teal !important;
  }

  &-grey {
    background-color: $text-color-light;
  }

  &-light-grey {
    background-color: $light-grey;
  }

  &-lighter-grey {
    background-color: $lighter-grey;
  }

  &-body-color {
    background-color: $body-bg;
  }

  &-dark-blue {
    background-color: #002f6b;
  }

  &-orange {
    background-color: $orange;
  }

  &-green {
    background-color: $green;
  }

  &-gold {
    background-color: $gold;
  }
}
