@media only screen and (min-width: $tablet-min-width) and (max-width: 1024px) and (orientation: portrait) {
  .container-fluid {
    .app-container {
      &.question-container {
        .right-panel-container {
          .right-panel-content {
            .question-card {
              padding-left: 20px;
              margin-left: -15%;

              .question-text {
                width: 85%;
              }
            }
          }
        }
      }

      &.answer-container {
        .right-panel-container {
          .right-panel-content {
            .answer-card {
              margin-left: -20%;
            }

            div.answer-content {
              margin-right: 5px;
              margin-left: -10%;
              padding: 30px 20px;
            }
          }
        }
      }
    }
  }
}
