@import './../../../../styles/variables';

.question-title {
  padding: 0 135px 0 15px;
  font-size: 16px;
  min-height: 80px;

  @include media-breakpoint-down(sm) {
    min-height: 93px;
    line-height: 20px;

    &.special {
      padding: 0 3px 0 15px;
      width: 100%;

      span {
        &.special-p {
          padding-right: 120px;
          display: block;

          &.full {
            padding-right: 0;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 340px) {
    padding: 0 101px 0 15px;
    min-height: 80px;

    &.special {
      span {
        &.special-p {
          padding-right: 90px;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    text-align: right;
    min-height: 0;
    margin-left: -95%;
    margin-right: -3px;
    padding: 0px 20px 10px 20px;
    font-size: 18px;
  }
}

body .container-fluid .bg-light-cyan.question-left-part {
  @include media-breakpoint-down(sm) {
    background: transparent;
  }
}

.question-container {
  + .next-button {
    display: none !important;
  }

  .right-panel-container {
    .right-panel-content {
      @include media-breakpoint-down(sm) {
        .divider-wrapper {
          display: block;
          margin: 27px 15px 10px;
        }
      }

      .question-card {
        background: $white;
        padding: 20px 0;

        @include media-breakpoint-up(md) {
          margin-left: -15%;
          padding: 30px 25px;
          -webkit-box-shadow: 0px 4px 4px $card-box-shadow-color;
          box-shadow: 0px 4px 4px $card-box-shadow-color;
          margin-right: 15px;
        }

        .question-text {
          display: inline-block;
          width: 88%;
          font-size: 18px;
          color: $dark-blue;

          @include media-breakpoint-down(sm) {
            font-size: 14px;
            line-height: 20px;
            color: rgb(27, 20, 102);
            width: 100%;
            padding-right: 34px;
          }
        }

        .question-choice {
          display: inline;
        }

        .checkmark {
          position: absolute;
          top: 0;
          right: 0;
          height: 40px;
          width: 40px;
          background-color: transparent;
          border-radius: 50%;
          border: 3px solid $dark-blue;

          &:after {
            content: '';
            position: absolute;
            display: none;
          }

          @include media-breakpoint-down(sm) {
            width: 30px;
            height: 30px;
            top: 50%;
            margin-top: -15px;
            margin-right: 10px;
          }
        }

        label {
          width: 100%;
          display: block;
          position: relative;
          margin-bottom: 20px;
          cursor: pointer;
          user-select: none;
          border-bottom: 3px solid $lighter-grey;
          padding-bottom: 20px;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          @include media-breakpoint-down(sm) {
            background-color: rgba(190, 187, 214, 0.34);
            border-bottom: 0;
            margin-bottom: 5px;
            border-radius: 5px;
            padding: 12px 14px;
          }

          i {
            font-size: 25px;
            color: $dark-blue;
            z-index: 999;
            position: absolute;
            padding: 5px 0 0 9px;
            @include media-breakpoint-down(sm) {
              font-size: 15px;
            }
          }
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            &:checked ~ .checkmark {
              background: $gold;
            }
          }

          .checkmark {
            &:after {
              top: 9px;
              left: 9px;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: $gold;
            }
          }

          &.valid-true,
          &.valid-false {
            i {
              padding: 5px 0px 0px 5px;
            }
          }

          &:nth-child(3) {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0px;

            @include media-breakpoint-down(sm) {
              padding-bottom: 14px;
            }
          }
        }
      }
    }
  }

  &.question-six-container {
    .right-panel-container {
      .right-panel-content {
        .question-title {
          @include media-breakpoint-up(md) {
            margin-left: -20%;
            margin-top: -5%;
          }
        }
      }
    }
  }
}
