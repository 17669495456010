.btn-arrow-right {
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    border-bottom: 20px solid transparent;
    border-top: 20px solid transparent;
    height: 0px;
    width: 0px;
    margin-right: -20px;
    right: 0;
    border-left: 20px solid white;
  }
  &:hover,
  &:active {
    background: yellow;
    color: black;
    &:after {
      border-left: 20px solid yellow;
    }
  }
}

.arrow {
  cursor: pointer;
  display: inline-block;
  height: 40px;
  margin-left: 40px;
  margin-right: 40px;
  position: relative;
  line-height: 2.5em;
  padding-left: 1em;
  padding-right: 2em;
  background: white;
  color: black;
  &:after {
    border-left: 20px solid white;
  }
}

.arrow:after {
  content: '';
  position: absolute;
  border-bottom: 20px solid transparent;
  border-top: 20px solid transparent;
  height: 0px;
  width: 0px;
  margin-right: -20px;
  right: 0;
}

.arrow:hover,
.arrow:active {
  background: yellow;
  color: black;
  &:after {
    border-left: 20px solid yellow;
  }
}

.chevron::before {
  border-style: solid;
  border-width: 5px;
  content: '';
  display: inline-block;
  height: 60px;
  left: 0.15em;
  position: relative;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 60px;
  border-left: none;
  border-bottom: none;
  float: right;
  margin-right: -95px;
}

.chevron.right:before {
  left: 0;
  transform: rotate(45deg);
}

.chevron.bottom:before {
  top: 0;
  transform: rotate(135deg);
}

.chevron.left:before {
  left: 0.25em;
  transform: rotate(-135deg);
}



.btn{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: "Serrano Pro";
  font-size: 20px;
  color: $dark-blue;
  
  border-radius: 0;
  padding: 0 26px;
  height: 56px;
  line-height: 1;

  border: none;
  cursor: pointer;
  opacity: .9;
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  -ms-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
  outline: none;

  &:hover{
    opacity: 1;
    box-shadow: 0 0 0 0;
  }

  &.btn-primary {
    background-color: transparent;
    border: 2px solid $button-primary-border-color;
    border-radius: 0;
    color: $button-primary-text-color;
    @include font-sizing(18, 20);

    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus {
      background-color: $button-primary-bg-hover-color;
      border-color: $button-primary-border-color;
      color: $button-primary-hover-text-color;
      transform: scale(1);
      transition: 0.25s;
      box-shadow: 0 0 0 0;
    }
  }

  &.btn-outlined{
    background-color: transparent;
    border: 2px solid $dark-blue;
    &:hover{
      background-color: $dark-blue;
      color: $white;
    }
  }

  &.btn-white{
    border-color: $white;
    color: $white;
    &:hover{
      background-color: $white;
      color: $dark-blue;
    }
  }
}