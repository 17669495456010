// Dynamic font siing
// 
// Dynamicly changes the font size based on the screen breakpoint

@mixin font-sizing($min_font, $max_font) {

  $min_width: 576; //map-get($grid-breakpoints, sm);
  $max_width: 1200; //map-get($grid-breakpoints, xl);

  font-size: #{$min_font}px;

  // This part is a little bit ugly, but it works!
  @media (min-width: #{$min_width}px) and (max-width: #{$max_width}px){
    font-size: calc( #{$min_font}px + (#{$max_font} - #{$min_font}) * ( (100vw - #{$min_width}px) / ( #{$max_width} - #{$min_width}) ));
  }
  @media (min-width: #{$max_width}px){
    font-size: #{$max_font}px;
  }
  
}
