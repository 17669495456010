.alertWrapper {
  z-index: 9999;

  h1 {
    font-size: 20px;
    color: #1b1464;
    text-align: center;
    line-height: 22px;
  }

  p {
    font-size: 16px;
    color: #3b3f4b;
    letter-spacing: -0.6px;
    text-align: center;
    line-height: 20px;
    margin-bottom: 24px;
    margin-top: 5px;
  }
}

.alert {
  position: absolute;
  margin: 0 auto;
  z-index: 300;
  min-height: 124px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  display: block;
  width: 315px;
  top: 126px;
  transform: translateX(-50%);
  background: #f0f0f0;
  padding: 40px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(#000000, 0.5);
  z-index: 200;
}

.btn,
.btnLeft {
  border: 2px solid #1b1464;
  display: block;
  width: 188px;
  height: 46px;
  line-height: 40px;
  font-style: normal;
  font-size: 16px;
  color: #1b1464;
  letter-spacing: -0.3px;
  margin: 0 auto;
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  -ms-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: #fff;
    background-color: #1b1464;
  }
}

.btnLeft {
  margin-right: 11px;
}
