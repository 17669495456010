/*
 * Override max width
 */
.container {
  max-width: 1440px;
}

/**
 * Hub Page styles
 */
.img-fluid{
  max-width: 100%;
  height: auto;
}

.h-100{
  height: 100%;
}

.text-nowrap{
  white-space: nowrap;
}

.text-center{
  text-align: center;
}

a{
  color: $white;
  text-decoration: none !important;
  &:hover{
    color: $white;
  }
}

h1, h2, h3, h4, h5, h6{
  font-weight: 300;
}

main{
  section.content{
    h2.title{
      color: $blue;
      line-height: 1;
      @include font-sizing(36, 62);
      max-width: 830px;
      margin: 0 auto;
    }

    h3.title{
      line-height: 1.2;
      @include font-sizing(28, 42);      
    }
    
    .scam-description{
      @include font-sizing(16, 20);
      line-height: 1.2;
      max-width: 445px;
      margin: 0 auto;
    }

    .scam-savvy,
    .scam-savvy-2{
      padding: 85px 80px 100px;
      height: 100%;
      text-align: center;
      
      @media (max-width: 1024px) {
        padding: 85px 45px;
      }

      img{
        -webkit-transition: all .25s ease-in-out;
        -moz-transition: all .25s ease-in-out;
        -ms-transition: all .25s ease-in-out;
        -o-transition: all .25s ease-in-out;
        transition: all .25s ease-in-out;
      }

      &:hover{
        // border: 22px solid rgba(255,255,255,0.5);

        img{
          -webkit-transform: scale(1.05);
          -moz-transform: scale(1.05);
          -ms-transform: scale(1.05);
          -o-transform: scale(1.05);
          transform: scale(1.05);
        }
      }

      a{
        text-decoration: none;
      }
    }

    .scam-savvy{
      background-color: $teal-dark;
      color: $dark-blue;

      &:hover{
        // background-color: darken($orange, 5);
        .btn-outlined{
          background-color: $dark-blue;
          color: $white;
        }
      }
    }

    .scam-savvy-2{
      background-color: $aubergine;
      &:hover{
        .btn-outlined{
          &.btn-white{
            background-color: $white;
            color: $dark-blue;
          }
        }
      }
    }

    .bg-blue{
      background-color: $dark-blue;
    }

    .blue-block {
      display: block;
      position: relative;
      text-align: center;
      padding: 130px 80px;

      @media (max-width: 1024px) {
        padding: 75px 25px;
      }

      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-color: $dark-blue transparent;
        border-width: 60px 60px 0;
        bottom: -59px;
        left: 50%;
        margin-left: -60px;
      }

      h2.title{
        color: $white;
      }

      .report-card{
        height: 100%;
        font-size: 24px;
        line-height: 1.25;
        color: $white;
        padding-bottom: 50px;

        @media (min-width: 768px) {
          text-align: left;
          border-left: 1px solid $white;
          padding-left: 24px;
          padding-bottom: 15px;
        }

        .badge{
          color: $dark-blue;
          background-color: $teal-dark;
          font-size: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 38px;
          height: 38px;
          border-radius: 50%;
          margin: 0 auto 20px;

          @media (min-width: 768px) {
            margin: 0 0 20px 0;
            text-align: left;
          }
        }
      }
    }

    .white-block{
      background-color: $white;
      padding: 80px 80px 50px;
      text-align: center;

      @media (max-width: 768px) {
        padding: 80px 25px 25px;
      }
    }
  }
}