@import '../../styles/variables.scss';

.download-section-wrapper {
  color: $blue;
  padding: 115px 20px 120px;

  @media (max-width: $tablet-min-width) {
    padding: 35px 25px 65px;
    text-align: center;
  }

  h2 {
    @include font-sizing(28, 42);
    line-height: 121%;
  }

  img {
    width: 274px;
    margin: auto;

    @media (max-width: $tablet-min-width) {
      width: 159px;
    }
  }

  .divider {
    width: 76px;
    height: 6px;
    background-color: $blue;
  }

  p {
    color: $charcoal;
    @include font-sizing(18, 28);
    line-height: 135.5%;
  }

  a.btn {
    margin: auto;

    &:first-child {
      margin-right: 1rem;

      @media (max-width: $tablet-min-width) {
        margin-right: auto;
      }
    }
  }
}