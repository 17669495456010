@import './../../styles/variables';

section {
  .intro-text {
    position: relative;
    @include font-sizing(18, 28);
    line-height: 1.35;
  }

  h2 {
    @include font-sizing(28, 42);
    color: $dark-blue;
    line-height: 1.2;
    margin: 0;
  }

  .centre-it {
    max-width: 1200px;
    margin: 0 auto;
  }

  .scroll-icon{
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid $dark-blue;
    border-radius: 50%;
    width: 85px;
    height: 85px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &.step-loading {
    background: $lighter-grey;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;

    .content-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .loader-content {
        text-align: center;

        .intro-text {
          font-size: 42px;
          line-height: 1.2;
          margin-top: 65px;
          color: $dark-blue;
          font-weight: 300;
        }
      }
    }
  }

  .steps{
    position: relative;
  }

  &.step-one{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - #{$header-height});
    padding: 100px 25px;

    @include media-breakpoint-down(md) {
      padding: 80px 25px;
      min-height: calc(100vh - #{$header-height-mobile});
    }

    .img-scam-savvy{
      display: block;
      margin: 0 auto;

      @include media-breakpoint-down(sm) {
        max-width: 248px;
      }
    }

    .intro-text {
      max-width: 826px;
    }

    .down-arrow {
      margin-top: 85px;

      @include media-breakpoint-down(sm) {
        margin-top: 50px;
      }
    }
  }
  &.step-one-business{
    @extend .steps;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - #{$header-height});

    @include media-breakpoint-down(md) {
      min-height: calc(100vh - #{$header-height-mobile});
    }

    .top{
      display: flex;
      justify-content: center;
      overflow-x: hidden;
      margin-bottom: -30px;
      z-index: 1;

      .img-business{
        @include media-breakpoint-down(sm) {
          max-height: 132px;
        }
      }
    }

    .bottom{
      padding: 100px 25px 60px;
      flex-grow: 1;

      @include media-breakpoint-down(md) {
        padding: 80px 25px 55px;
      }
    }


    .img-scam-savvy{
      @include media-breakpoint-down(sm) {
        max-width: 248px;
      }
    }

    .intro-text {
      max-width: 826px;
    }

    .down-arrow {
      margin-top: 85px;

      @include media-breakpoint-down(sm) {
        margin-top: 50px;
      }
    }
  }
  &.step-one-a-half{
    .content-wrapper {
      padding: 40px 20px;

      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }
    .row > div{
      display: flex;
    }
    p {
      @include font-sizing(18, 20);
    }
    button {
      margin: auto;
      margin-right: 0;
      @include media-breakpoint-down(md) {
        margin-right: auto;
      }
    }
  }

  &.step-two, &.step-four {
    @extend .steps;
    .content-wrapper {
      padding: 150px 80px;

      @include media-breakpoint-down(sm) {
        padding: 75px 25px;
        text-align: center;

        .main-row {
          .main-row-header {
            order: 1;
            margin-top: 30px;
          }

          .main-row-body {
            order: 2;
          }

          .main-row-pic {
            order: 0;
          }
        }
      }

      .image-content {
        @include media-breakpoint-down(sm) {
          width: 159px;
        }

        img {
          max-width: 100%;
        }
      }

      button{
        float: left;
        @include media-breakpoint-down(sm) {
          float: none;
        }
      }
    }
  }

  &.step-three {
    @extend .steps;
    .content-wrapper {
      padding: 150px 80px;

      @include media-breakpoint-down(sm) {
        padding: 75px 25px;
        text-align: center;

        .main-row {
          .main-row-header {
            order: 1;
            margin-top: 30px;
          }

          .main-row-body {
            order: 2;
          }

          .main-row-pic {
            order: 0;
          }
        }
      }

      .image-content {
        @include media-breakpoint-down(sm) {
          width: 159px;
        }

        img {
          max-width: 100%;
        }
      }
    }
  }

  

  &.step-five {
    @extend .steps;
    .content-wrapper {
      padding: 150px 80px;

      @include media-breakpoint-down(sm) {
        padding: 75px 25px;
        text-align: center;
      }

      .image-content {
        @include media-breakpoint-down(sm) {
          width: 260px;
        }

        img {
          max-width: 100%;
          cursor: pointer;
        }
      }
    }
  }

  &.step-results {
    .content-wrapper {
      padding: 70px 30px;

      .intro-text {
        @include font-sizing(28, 42);
        line-height: 1.2;
        color: $dark-blue;
        font-weight: 300;
      }
    }
  }

  &.results-one {
    @extend .step-two;
  }
  
  &.results-two {
    @extend .step-three;
    .content-wrapper {
      .image-content {
        @include media-breakpoint-down(sm) {
          width: 159px;
        }
      }
    }
  }

  &.results-three {
    .content-wrapper {
      padding: 150px 80px;

      @include media-breakpoint-down(sm) {
        padding: 75px 25px;
      }

      h2 {
        @include font-sizing(36, 62);
        line-height: 1.2;
        margin: 0 auto;
      }

      ul li {
        margin-bottom: 10px;
        @include font-sizing(16, 24);
      }
    }
  }

  &.results-four {
    .content-wrapper {
      position: relative;
      padding: 150px 80px;

      @include media-breakpoint-down(sm) {
        padding: 75px 25px;
      }

      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-color: $dark-blue transparent;
        border-width: 60px 60px 0;
        bottom: -59px;
        left: 50%;
        margin-left: -60px;
      }

      h2 {
        @include font-sizing(36, 62);
        line-height: 1.2;
        max-width: 828px;
        margin: 0 auto;
      }

      .report-card {
        height: 100%;
        font-size: 24px;
        line-height: 1.25;
        color: $white;
        padding-bottom: 50px;
        text-align: center;

        @include media-breakpoint-up(md) {
          text-align: left;
          border-left: 1px solid $white;
          padding-left: 24px;
          padding-bottom: 15px;
        }

        .badge {
          color: $dark-blue;
          background-color: $teal;
          font-size: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 38px;
          height: 38px;
          border-radius: 50%;
          margin: 0 auto 20px;
          text-align: center;

          @include media-breakpoint-up(md) {
            margin: 0 0 20px 0;
            text-align: left;
          }
        }
      }
    }
  }

  &.results-five {
    .content-wrapper {
      padding: 150px 80px 50px;

      @include media-breakpoint-down(sm) {
        padding: 110px 25px 25px;
      }
    }
  }
}
